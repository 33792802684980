import React, { useContext } from "react";

// import { languageOptions } from "../languages";
import { LanguageContext } from "../containers/Language";

const spainFlag = require("../lang-icons/icon-spain.png");
const englishFlag = require("../lang-icons/icon-english.png");


export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  // set selected language by calling context method
  const handleLanguageChange = (e) => {
    userLanguageChange(e.target.value);
  };

 

  return (
    <div>
      <div onChange={handleLanguageChange} value={userLanguage} style={{marginTop: "5px", color: 'white', border: '1px solid #1e90ff', padding: '5px 5px 5px 10px', borderRadius: '25px' }} >
        {userLanguage === "es" ?
        <label className="no-select" style={{ cursor: 'pointer' }} htmlFor="english" >
        <div className="flex-center">
        English &nbsp;
        <input type="checkbox" id="english" style={{display:"none"}} value={"en"}> 
        </input>
        <div className="icon-container">
            <img src={englishFlag} style={{width:40}} alt="" />
        </div>    
        </div>    
        </label>
        
  :
        <label className="no-select" style={{ cursor: 'pointer' }} htmlFor="span" >
        <div className="flex-center">
        Español &nbsp;
        <input type="checkbox" id="span" style={{display:"none"}} value={"es"}> 
        </input>
        <div className="icon-container">
            <img src={spainFlag}  style={{width:40, cursor: 'pointer'}} alt="" />
        </div>
        </div>
        </label>
}    
      </div>
      
    </div>
  );
}
