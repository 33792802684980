import "./home.css";


import lconoGithub from "../../src/social-media-icons/icons8-github.svg";
import lconoLinkedin from "../../src/social-media-icons/icons8-linkedin.svg";
import lconoStackoverflow from "../../src/social-media-icons/icons8-stackovelflow.svg";
import lconoTwitter from "../../src/social-media-icons/icons8-twitter.svg";
import lconoFacebookMenuSvg from "../../src/social-media-icons/icons8-facebook.svg";

import { Text } from '../containers/Language';
import TypingAnimation from "./TypingAnimation";

import { useContext } from "react";
import { LanguageContext } from "../containers/Language.js";

const foto = require("../assets/gerardluisbv-avatar.jfif");
const Home = () => {
  
  const { dictionary } = useContext(LanguageContext);

  return (

    <div className="home animate__animated animate__fadeIn animate__delay-0.8s no-select">
      <div className="container_home homeImage profile">
        <div className="content_about gradient-border-box" style={{ position: 'relative'}}>
        <div className="avatar">
          <img src={foto} alt="Mi Avatar"/>
        </div>
          <div>
            <h1 className="title_principal">
              <TypingAnimation text = { dictionary["title-home"] }/>
            </h1>
            {/* <h1 className="title_principal"> <Text tid={"title-home"}/> </h1> */}
          </div>

          <div className="contact_details">
            <h3>
            <Text tid={"content-home"}/>
            </h3>
              <blockquote style={{ fontSize : "14px" }}>
                {" "}
              <Text tid={"quote-home"}/>
              
              </blockquote>
          </div>
          <hr />
          <div className="social_home">
            <div>
              {" "}
              <a href="https://github.com/gerardoluisbv/" target="blank">
                <img
                  className="social_icon_home"
                  src={lconoGithub}
                  alt="logo"
                />{" "}
              </a>
            </div>

            <div>
              <a href="https://twitter.com/gerardoluisbv/" target="blank">
                {" "}
                <img
                  className="social_icon_home"
                  src={lconoTwitter}
                  alt="logo"
                />{" "}
              </a>
            </div>
            <div>
              {" "}
              <a
                href="https://stackoverflow.com/users/13405980/gerardoluisbv"
                target="blank"
              >
                <img
                  className="social_icon_home"
                  src={lconoStackoverflow}
                  alt="logo"
                />{" "}
              </a>
            </div>
            <div>
              {" "}
              <a
                href="https://www.linkedin.com/in/gerardoluisbv/"
                target="blank"
              >
                <img
                  className="social_icon_home"
                  src={lconoLinkedin}
                  alt="logo"
                />{" "}
              </a>
            </div>
            <div>
              {" "}
              <a
                href="https://www.facebook.com/gerardo.luis.1654"
                target="blank"
                alt="Facebook"
              >
                <img
                  className="social_icon_home"
                  src={lconoFacebookMenuSvg}
                  alt="logo"
                />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
  
    </div>

    
  );
};

export default Home;
