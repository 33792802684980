
import { NavLink, useLocation  } from "react-router-dom";

import styled from "styled-components";
import IconHomeSvg from "../../icons/IconHomeSvg";
import IconAbout from "../../icons/IconAbout";
import IconResume from "../../icons/IconResume";
import IconWorks from "../../icons/IconWorks";
import IconContact from "../../icons/IconContact";
import { useEffect, useRef } from "react";

const StyledLink = styled(NavLink)`
  width: 25px;
  display: flex;
  justify-content: center;

  svg {
    fill: #f0f8ff;
    height: 35px;
  }

  &.active {
    color: #0093d3;

    svg {
      fill: #0093d3;
      border-bottom: 2px solid #0093d3;
      margin-bottom: 2px;
      padding-bottom: 2px;
    }
  }

  @media (min-width: 990px) {
    margin-bottom: 2vh;
    width: 25px;
    height: 25px;
  }
`;

const Main = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  bottom: 0px;
  
  z-index: 1;
  
  @media (min-width: 990px) {
    width: 30px;
    bottom: 30px;
    right: 30px;
  }

  @media (max-width: 990px) {
    padding-bottom : 8px;
    padding-top : 8px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const NavBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  
  @media (min-width: 990px) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;


  }
`;

// background: darkslategrey;
//     border-radius: 30px;
//     padding: 5px;


const BottomNavigation = () => {

  const location = useLocation();
  const isMobile = useRef();
  const isResumePage = location.pathname === "/resume";
  
  useEffect(() => {
    isMobile.current = window.innerWidth <= 990;
  }, []);
  

  return (
    <>
    <Main style={{ 
            background : isResumePage &&  isMobile  ? "#1a1b26" : 'transparent',                  
            }} className="menu-bottom">
      <Content>
        <NavBottom className="button_menu" >
          <StyledLink to="/" aria-label="home">
            <IconHomeSvg color={"#FFF"} />
          </StyledLink>

          <StyledLink to="/about" aria-label="about">
            <IconAbout />
          </StyledLink>

          <StyledLink to="/works" aria-label="works">
            <IconWorks />
          </StyledLink>

          <StyledLink to="/resume" aria-label="resume">
            <IconResume />
          </StyledLink>

          <StyledLink to="/contact" aria-label="contact">
            <IconContact />
          </StyledLink>
        </NavBottom>
      </Content>
    </Main>
    </>
  );
};

export default BottomNavigation;
