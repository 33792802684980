import React, { useState, useEffect } from 'react';

const TypingAnimation = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  
  useEffect(() => {
    let currentIndex = 0;
    
    const intervalId = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayText(text.slice(0, currentIndex + 1));
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, 100); // Ajusta la velocidad de escritura aquí

    return () => {
      clearInterval(intervalId);
    };
  }, [text]);

  return (
    <span>{displayText}</span>
  );
};

export default TypingAnimation;
