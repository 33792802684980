import { useState } from "react";
import { NavLink } from "react-router-dom";

import "./Navbar.css";

import menuBurguer from "../../../src/menuBurguerWhite.svg";
import menuBurguerClose from "../../../src/closeMenuWhiteX.svg";
import lconoMenuSvg from "../../../src/logoGerardoluisbvAI.svg";

import {  useLocation  } from "react-router-dom";

import LanguageSelector from '../LanguageSelector';
import { Text } from '../../containers/Language.js';

const logo = require("../../assets/logo-glbv.png");

export const Navbar = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  
  const location = useLocation();
  const isResumePage = location.pathname === "/resume";
  const isMobile = window.innerWidth <= 990;

  return (
    <>
      <header className="navbar"  style={{ 
            background : isResumePage &&  isMobile  ? "#1a1b26" : '',
            zIndex: '1'
            }}>
        <div className="navbar-header__container" >
          <div style={{display: "inherit",  alignItems :"center" }}>

          <div className="nav-left" onClick={showSidebar}>
            <img
              className="icons" style={{ cursor : 'pointer' }}
              src={menuBurguer}
              alt="logo"
            />

          </div>
          <LanguageSelector />
          </div>

        <a href="/" className="pointer">
          <div className=" nav-right" style={{ zIndex: 0 }}>
            <img className="iconLogoMenu"  src={lconoMenuSvg} alt="logo" />
          </div>
        </a>    
        </div>
      </header>

      <div
        className={sidebar ? "left-col-backdrop active" : "left-col-backdrop"}
        onClick={showSidebar}
      >
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
            <img style={{position: "absolute", top:"5px", right: "0px",  cursor : 'pointer'}}
              className="icons"
              src={menuBurguerClose}
              alt="logo"
            />
        <div className="nav-left" onClick={showSidebar}>

          </div>
          <div className="nav-menu-items" onClick={showSidebar}>
            <NavLink
              className={({ isActive }) =>
              "nav-text" + (isActive ? " activated" : "")
              }
              to="/"
            >
              <span> <Text tid={"nav-opt1"} /> </span>
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                "nav-text" + (isActive ? " activated" : "")
              }
              to="/about"
            >
              <span> <Text tid={"nav-opt2"} />  </span>
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                "nav-text" + (isActive ? " activated" : "")
              }
              to="/works"
            >
              <span> <Text tid={"nav-opt3"} />  </span>
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                "nav-text" + (isActive ? " activated" : "")
              }
              to="/resume"
            >
              <span> <Text tid={"nav-opt4"} />  </span>
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                "nav-text" + (isActive ? " activated" : "")
              }
              to="/contact"
            >
              <span> <Text tid={"nav-opt5"} />  </span>
            </NavLink>
          </div>
          <img className="nav-menu-logo" src={logo} alt="" />
        </nav>
      </div>
    </>
  );
};

export default Navbar;
