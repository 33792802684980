import "./App.css";


// import Particles from "react-tsparticles";
// import { loadFull } from "tsparticles";
import { AppRouter } from "./routers/AppRoutes";





function App() {
  // const particlesInit = async (main) => {
  //   // console.log(main);

  //   // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  //   // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  //   // starting from v2 you can add only the features you need reducing the bundle size
  //   await loadFull(main);
  // };

  // const particlesLoaded = (container) => {
  //   // console.log(container);
  // };

  return (
    <>
      
       
           <AppRouter/>
      

      {/* <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fpsLimit: 30,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 0,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#3fa9f5",
            },
            links: {
              color: "#3fa9f5",
              distance: 150,
              enable: true,
              opacity: 0.1,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1,
              straight: true,
            },
            number: {
              density: {
                enable: true,
                area: 1500,
              },
              value: 120,
            },
            opacity: {
              value: 0.2,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 1 },
            },
          },
          detectRetina: true,
        }}
      />

 */}
    </>
    );
}

export default App;
