
import { useRef, useContext, useState } from "react";
import emailjs from "@emailjs/browser";
import { Text, LanguageContext } from "../containers/Language.js";
import TypingAnimation from "./TypingAnimation.jsx";

import swal from 'sweetalert';
import "../styles/form.css";

const id = process.env.REACT_APP_YOUR_SERVICE_ID;
const template = process.env.REACT_APP_YOUR_TEMPLATE_ID;
const key = process.env.REACT_APP_YOUR_PUBLIC_KEY;

const Contact = () => {
  const { dictionary } = useContext(LanguageContext);

  const form = useRef();

  const [loading, setloading] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    // "YOUR_SERVICE_ID",
    // "YOUR_TEMPLATE_ID",
    // form.current,
    // "YOUR_PUBLIC_KEY"

    setloading(true)

    emailjs
      .sendForm(
        id,
        template,
        form.current,
        key
      )
      .then(
        (result) => {
          console.log(result.text);
          swal({
            title: dictionary["alert-messaje-contact-success-a"],
            text:dictionary["alert-messaje-contact-success-b"]})
        },
        (error) => {
          console.log(error.text);
          swal(dictionary["alert-messaje-contact-fail"]);
        }
      ).finally(()=> {
        setloading(false)
      });
      e.target.reset();
  };

  return (
    <div className="home animate__animated animate__fadeIn animate__delay-0.8s no-select">
      <div className="container_home homeImage" style={{maxWidth : "800px"}}>
        <div className="content_about">
          <div className="title_home">
             <h1 className="title_principal">
               <TypingAnimation text = { dictionary["title-contact"] }/>
             </h1>
          </div>
          <hr />

          <div className="container-form">
            
            <form ref={form} onSubmit={sendEmail}>
            <div>
              <label htmlFor="fname"> <Text tid={"label-form-name-contact"} /> </label>
              <input type="text" id="lname" name="user_name" placeholder= {dictionary["input-form-name-contact"]} required />
            </div>

            <div>
              <label htmlFor="email"><Text tid={"label-form-email-contact"} /> </label>
              <input type="email" id="email" name="user_email" placeholder={dictionary["input-form-email-contact"]} required  autoComplete="on"/>
            </div>

            <div>
              <label htmlFor="message"><Text tid={"label-form-message-contact"} /> </label>
              <textarea name="message" id="message" placeholder={dictionary["input-form-message-contact"]} required/>
            </div>

            <div>
              <input className="button-form" 
              type="submit" 
              style={ loading ? { background : "#9B9B9B", cursor : "not-allowed"} : undefined }
              value={loading  ? dictionary["title-button-form-loading"] : dictionary["title-button-form-contact"]} 
              disabled = {loading}/>
            </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
