import { useContext } from "react";
import { LanguageContext } from "../containers/Language.js";
import myPdf from "./cv-gerardoluisbv-2023.pdf";
import { Text } from '../containers/Language.js';
import TypingAnimation from "./TypingAnimation.jsx";
const foto_graduacion = require("../assets/gerardoluisbv-graduacion.jpg");

const About = () => {
  const { dictionary } = useContext(LanguageContext);
  
  return (
    <div className="home animate__animated animate__fadeIn animate__delay-0.8s no-select">
      <div className="container_home homeImage about">
        <div className="content_about" style={{ position: "relative" }}>
          <div className="title_home">
            <h1 className="title_principal">
              <TypingAnimation text = { dictionary["title-about"] }/>
            </h1>
          </div>
          <div className="contact_details" >
            <h3>
            <Text tid={"content-about"} />
            </h3>

          <div>

              <h3 className="sub_title"> <Text tid={"title2-about"} /> </h3>
              <h3> Gerardo Luis B. Velasquez. </h3>
              <h3> ( +507 ) 63246164 </h3>
              <h3> gerardoluisbv@gmail.com </h3>
            <div  className="avatar profile_absolute" >
              <img src={ foto_graduacion } alt="" />
            </div>
          </div>
        </div>

          <hr className="color-transition" />
          <br />
          <a href={myPdf} target="_blank" rel="noopener noreferrer" download>
            <button className="button_download">
              <svg
                id="svg-buttom"
                data-name="Capa 3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 44.85 40.62"
              >
                <path
                  d="M43.43,36.15l-9.32,7.08a1.51,1.51,0,0,1-.65.31,1.52,1.52,0,0,1-.6,0,1.42,1.42,0,0,1-.61-.28l0,0-9.32-7.08a1.5,1.5,0,1,1,1.82-2.38l7,5.28V12.55a1.5,1.5,0,1,1,3,0v26.5l7-5.28a1.5,1.5,0,1,1,1.82,2.38Z"
                  transform="translate(-10.78 -11.05)"
                  fill="#fff"
                />
                <path
                  d="M55.63,38.51v7.57A5.6,5.6,0,0,1,50,51.67H16.38a5.6,5.6,0,0,1-5.6-5.59V38.51a1.5,1.5,0,0,1,3,0v7.57a2.59,2.59,0,0,0,2.6,2.59H50a2.59,2.59,0,0,0,2.59-2.59V38.51a1.5,1.5,0,0,1,3,0Z"
                  transform="translate(-10.78 -11.05)"
                  fill="#fff"
                />
              </svg>
              &nbsp; <Text tid={"title-button-download"} />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
