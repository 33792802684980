
import Slider from "react-slick";
import {  LanguageContext } from "../containers/Language.js";
import { useContext } from "react";
import TypingAnimation from "./TypingAnimation.jsx";
const Works = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { dictionary } = useContext(LanguageContext);

  
  return (
    <div
      className="home animate__animated animate__fadeIn animate__delay-0.8s no-select"
      style={{ overflow: "hidden" }}
      >
     
      <div className="container_home homeImage" style={{ width: "90%" }}>
        <div className="content_about">
          <div className="title_home">
    
              <h1 className="title_principal">
                <TypingAnimation text = { dictionary["title-projects"] }/>
              </h1>
              {/* <Text tid={"title-projects"} /> */}
           
          </div>

          <Slider {...settings}>


          { dictionary["projects"].map((item, index) => (
            <div key={index}>
              <div className="contact_details">
                <h3>
                  {item["content-projects"]}
                </h3>
              </div>

              <hr />
            
            <div 
              style={{ 
                marginLeft: '5%', 
                marginRight: '5%',  
                width: '90%', 
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'space-evenly' , 
              }}              
              >

            {item["title-deploy-project"].map((enlace, indice) => (
                <h2 key={indice} className="text__link__proyect">
                  
                    <a
                      href={enlace.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {enlace.name}
                    </a>
                </h2>
              ))}
                
      
                  </div>

                  {item["button-project"].map((enlace, indice) => (
                      <a
                        key={indice}
                        href={enlace.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >                
                          <button key={indice}  className={`button_component button_download ${ indice > 0 ? 'padding-left' : ''}`}>
                            {enlace.name}
                          </button>
                      </a>
                  ))}
            </div>
           
          ))}
            
            
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Works;
