import { useContext } from "react";
import { LanguageContext } from "../containers/Language.js";
import TypingAnimation from "./TypingAnimation.jsx";

import "../styles/timeline.css";


const Resume = () => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <div className="home animate__animated animate__fadeIn animate__delay-0.8s no-select" style={{ zIndex: '0' }}>
      <div className="container_home homeImage">
        <div className="content_about" style={{ textAlign: "left" }}>
          <div className="title_home">
              <h1 className="title_principal" style={{ textAlign: "center" }}>
                <TypingAnimation text = { dictionary["title-resume"] }/>
              </h1>
            <hr />
          </div>
          <div className="timeline">
            <div  style={{
                paddingBottom: "15px",
                marginLeft: "10px",
              }}>
            <h3
              style={{
                fontWeight: "400",
                fontSize: "18px",
              }}
            >
              {" "}
              Company: Corporación La Prensa, Panamá{" "}
            </h3>
              <p>Position: Software Developer</p>
              <p>Start Date: May 2022 - Present</p>
            </div>
            <div className="outer">
              <div className="card">
                <div className="info">
                  <h3 className="title">Phyton</h3>
                  <p>
                    Phyton script development to move files between S3(AWS)
                    buckets, implemented in AWS Lambda function for daily
                    execution.{" "}
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="info">
                  <h3 className="title">Reactjs - ARC XP </h3>
                  <p>
                    Development and maintenance of different Reactjs components
                    and functionalities in corporate websites.{" "}
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="info">
                  <h3 className="title">Flutter</h3>
                  <p>News APP upgrade from Flutter 2 to Flutter 3. </p>
                </div>
              </div>
              <div className="card">
                <div className="info">
                  <h3 className="title">Photoshop - WordPress </h3>
                  <p>
                    Design and development of new subscription website
                    (Photoshop for design, Evolok API integration and widgets
                    for user session management).{" "}
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="info">
                  <h3 className="title">Google Ads</h3>
                  <p>
                    Implementation and maintenance of Google Ads advertising
                    spaces.{" "}
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="info">
                  <h3 className="title">Phyton</h3>
                  <p>
                    Python script development to rename and organize PDF files
                    of ePaper editions hosted in S3 buckets.{" "}
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="info">
                  <h3 className="title">PHP</h3>
                  <p>
                    Development and deployment of a new web template in PHP to
                    send newsletters by email.{" "}
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="info">
                  <h3 className="title">Photoshop - Illustrator</h3>
                  <p>
                    Design of advertising campaigns for subscriptions in
                    Photoshop and Illustrator.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
